<template>
    <div class="site-login w-100 p-xxl-5 p-xl-5 p-lg-3 p-md-2 p-sm-2 p-2">
        <h2 class="text-purple mb-3">Enter email to get password reset link</h2>
        <password-reset-form></password-reset-form>
    </div>
</template>

<script>
    import PasswordResetForm from "./PasswordResetForm.vue";

    export default {
        name: "password-reset",
        components: {
            PasswordResetForm,
        },
    };
</script>
